import { ErrorMessage, Field, Formik } from "formik";
import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiPlus, FiX } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { IoIosSave, IoIosCloseCircle, IoIosUndo, IoIosCheckmarkCircle, IoIosRemoveCircle } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import pt from "date-fns/locale/pt";

import Layout from "../../../components/Layout";
import SEO from "../../../components/SEO";
import Card from "../../../components/Cards";
import TitleC6 from "../../../components/Typography/TitleC6";
import Paginate from "../../../components/Paginate/Paginate";
import Table from "../../../components/styles/Table";
import NumberFormat from "react-number-format";

import ModalDocsUpload from "./ModalDocsUpload";
import ModalShowFile from "./ModalShowFile";
import ModalRemoveFile from "./ModalRemoveFile";
import ModalCancel from "./ModalCancel";
import ModalReturn from "./ModalReturn";
import ModalClient from "./ModalClient";
import ModalAproved from "./ModalAproved";
import ModalRejected from "./ModalRejected";

import api from "../../../services/api";
import requests from "../../../services/requests";

import SearchIcon from "../../../assets/svgs/search.inline.svg";
import DownloadIcon from "../../../assets/svgs/download.inline.svg";

const yup = require("yup");

registerLocale("pt", pt);

const EditBudgetForm = ({ entity, files }) => {
    const [action, setAction] = useState(1);
    const [aereoValue, setAereoValue] = useState("");
    const [clientsOptions, setClientsOptions] = useState([]);
    const [clientChanged, setClientChanged] = useState(false);
    const [currentEntity, setCurrentEntity] = useState({});
    const [ctrsMandatory, setCtrsMandatory] = useState(true);
    const [data, setData] = useState(files);
    const [disabledForm, setDisabledForm] = useState(false);
    const [downloadingAll, setDownloadingAll] = useState(false);
    const [downloadingFile, setDownloadingFile] = useState("");
    const [isLoadingClients, setIsLoadingClients] = useState(false);
    const [isLoadingProcess, setIsLoadingProcess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingAnalysisLogistics, setLoadingAnalysisLogistics] = useState(false);
    const [loadingAnalysisOperational, setLoadingAnalysisOperational] = useState(false);
    const [loadingAproved, setLoadingAproved] = useState(false);
    const [loadingCancell, setLoadingCancell] = useState(false);
    const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
    const [loadingReject, setLoadingReject] = useState(false);
    const [loadingReleaseClient, setLoadingReleaseClient] = useState(false);
    const [loadingReturn, setLoadingReturn] = useState(false);
    const [maritimoValue, setMaritimoValue] = useState("");
    const [meta, setMeta] = useState({
        current_page: 1,
    });
    const [modalityOptions, setModalityOptions] = useState([]);
    const [noClientOptionsMessage, setNoClientOptionsMessage] = useState("Nenhum resultado encontrado");
    const [noOptionsMessage, setNoOptionsMessage] = useState("Nenhum resultado encontrado");
    const [openModalAproved, setOpenModalAproved] = useState(false);
    const [openModalCancel, setOpenModalCancel] = useState(false);
    const [openModalClient, setOpenModalClient] = useState(false);
    const [openModalReject, setOpenModalReject] = useState(false);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const [openModalReturn, setOpenModalReturn] = useState(false);
    const [openModalShow, setOpenModalShow] = useState(false);
    const [portsOptions, setPortsOptions] = useState([]);
    const [processOptions, setProcessOptions] = useState([]);
    const [quoteStatus, setQuoteStatus] = useState({});
    const [requiredList, setRequiredList] = useState({
        incoterm: entity?.incoterm,
        modality: entity?.modality?.description
    });
    const [terrestreValue, setTerrestreValue] = useState("");

    const ctrTamanhoOptions = [
        { value: "20'", label: "20'" },
        { value: "40'", label: "40'" },
    ];
    const ctrTypeOptions = [
        { value: "Flat Rack", label: "Flat Rack" },
        { value: "GP", label: "GP" },
        { value: "Hard Top", label: "Hard Top" },
        { value: "High Cube", label: "High Cube" },
        { value: "Reefer", label: "Reefer" },
    ];
    const VehicleOptions = [
        { value: "DEDICADO", label: "Dedicado" },
        { value: "FRACIONADO", label: "Fracionado" },
    ];
    const VehicleTypeOptions = [
        { value: "CARRETA_BAU", label: "Carreta Baú" },
        { value: "SIDER", label: "Sider" },
        { value: "TRUCK", label: "Truck" },
        { value: "TOCO", label: "Toco" },
    ];
    const quoteTypeOptions = [
        { value: "FCL", label: "FCL" },
        { value: "LCL", label: "LCL" },
    ];
    const incotermOptions = [
        { value: "EXW", label: "EXW – Ex Works – Na Origem (local de entrega nomeado)" },
        { value: "FCA", label: "FCA – Free Carrier – Livre No Transportador (local de entrega nomeado)" },
        { value: "FAS", label: "FAS – Free Alongside Ship – Livre Ao Lado Do Navio (porto de embarque nomeado)" },
        { value: "FOB", label: "FOB – Free On Board – Livre A Bordo (porto de embarque nomeado)" },
        { value: "CPT", label: "CPT – Carriage Paid To – Transporte Pago Até (local de destino nomeado)" },
        {
            value: "CIP",
            label: "CIP – Carriage And Insurance Paid To – Transporte E Seguro Pagos Até (local de destino nomeado)",
        },
        { value: "CFR", label: "CFR – Cost And Freight – Custo E Frete (porto de destino nomeado)" },
        { value: "CIF", label: "CIF – Cost Insurance And Freight – Custo, Seguro E Frete (porto de destino nomeado)" },
        { value: "DAP", label: "DAP – Delivered At Place – Entregue No Local (local de destino nomeado)" },
        {
            value: "DPU",
            label: "DPU – Delivered At Place Unloaded – Entregue No Local Desembarcado (Local de destino nomeado)",
        },
        { value: "DDP", label: "DDP – Delivered Duty Paid – Entregue Com Direitos Pagos (local de destino nomeado)" },
    ];
    const dangerousClassOptions = [
        { value: "", label: "Não perigoso" },
        { value: "1.1", label: "1.1" },
        { value: "1.2", label: "1.2" },
        { value: "1.3", label: "1.3" },
        { value: "1.4", label: "1.4" },
        { value: "1.5", label: "1.5" },
        { value: "1.6", label: "1.6" },
        { value: "2.1", label: "2.1" },
        { value: "2.2", label: "2.2" },
        { value: "2.3", label: "2.3" },
        { value: "3.1", label: "3.1" },
        { value: "3.2", label: "3.2" },
        { value: "3.3", label: "3.3" },
        { value: "3.4", label: "3.4" },
        { value: "3.5", label: "3.5" },
        { value: "4.1", label: "4.1" },
        { value: "4.2", label: "4.2" },
        { value: "4.3", label: "4.3" },
        { value: "5.1", label: "5.1" },
        { value: "5.2", label: "5.2" },
        { value: "6.1", label: "6.1" },
        { value: "6.2", label: "6.2" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
    ];

    function FormatString(name) {
        let nome = name?.toString();

        if (nome.length > 60) {
            nome = nome.substr(0, 60);
        }

        return nome;
    }

    function formatDocument(document) {
        if (document.length === 14) return document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        else return document;
    }

    const loadProcessList = (client = "", search = "") => {
        setNoOptionsMessage("Carregando");
        setIsLoadingProcess(true);

        if (search.length < 2 && client.length === 0) {
            setNoOptionsMessage("Insira ao menos 2 caracteres ou selecione um cliente para fazer a busca");
            setIsLoadingProcess(false);
        } else {
            api.get("process/list/all?page_size=1000" + (client ? `&clients[]=${client}` : ""), {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            })
                .then(response => {
                    var processList = [];

                    if (entity?.process && !clientChanged) {
                        processList.push({
                            value: entity?.processIdentifier,
                            label: entity?.processCode,
                        });
                    }

                    response.data.forEach(item => {
                        if (entity?.process && entity?.processIdentifier === item?.identifier && !clientChanged) {
                            return;
                        }

                        processList.push({
                            value: item?.identifier,
                            label: item?.code,
                        });
                    });

                    setNoOptionsMessage("Nenhum resultado encontrado");
                    setProcessOptions(processList);
                })
                .finally(() => {
                    setIsLoadingProcess(false);
                });
        }
    };

    const loadClientsList = (search = "", deafaultValue = {}) => {
        setNoClientOptionsMessage("Carregando");
        setIsLoadingClients(true);

        if (deafaultValue.identifier) {
            setClientsOptions([
                {
                    value: deafaultValue.identifier,
                    label: `${deafaultValue.name} - ${formatDocument(deafaultValue.documentNumber)}`,
                },
            ]);
        }

        if (search.length <= 2) {
            setNoClientOptionsMessage("Insira ao menos 2 caracteres para fazer a busca");
            setIsLoadingClients(false);
        } else {
            api.get(`quote/client/list?page_size=10000${search && `&search=${encodeURIComponent(search)}`}`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            })
                .then(response => {
                    var clients = [];

                    response.data.data.forEach(item => {
                        clients.push({
                            value: item?.identifier,
                            label: `${item?.name} - ${formatDocument(item?.documentNumber)}`,
                        });
                    });

                    setClientsOptions(clients);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        }
    };

    const Buttons = ({ isSubmitting }) => {
        if (entity?.status === "APPROVED" || quoteStatus?.status === "APPROVED") {
            return (
                <>
                    <p className="float-right h-10 px-4 w-auto text-roxo_oficial">
                        Aprovado em:
                        <br />
                        {entity?.closedAtFormatted ? entity?.closedAtFormatted : quoteStatus?.date}
                    </p>
                    <button
                        onClick={() => {
                            window.location.href = "/cotacao";
                        }}
                        className="float-right h-10 px-4 w-auto bg-C4 text-white rounded mx-2 flex items-center">
                        <IoIosUndo size={"1.5em"} />
                        <span className="text-xl ml-2">Voltar</span>
                    </button>
                </>
            );
        } else if (entity?.status === "REJECTED" || quoteStatus?.status === "REJECTED") {
            return (
                <>
                    <p className="float-right h-10 px-4 w-auto text-roxo_oficial">
                        Rejeitado em:
                        <br />
                        {entity?.closedAtFormatted ? entity?.closedAtFormatted : quoteStatus?.date}
                    </p>
                    <button
                        onClick={() => {
                            window.location.href = "/cotacao";
                        }}
                        className="float-right h-10 px-4 w-auto bg-C4 text-white rounded mx-2 flex items-center">
                        <IoIosUndo size={"1.5em"} />
                        <span className="text-xl ml-2">Voltar</span>
                    </button>
                </>
            );
        } else if (entity?.status === "CANCELLED" || quoteStatus?.status === "CANCELLED") {
            return (
                <>
                    <p className="float-right h-10 px-4 w-auto text-roxo_oficial">
                        Cancelado em:
                        <br />
                        {entity?.closedAtFormatted ? entity?.closedAtFormatted : quoteStatus?.date}
                    </p>
                    <button
                        onClick={() => {
                            window.location.href = "/cotacao";
                        }}
                        className="float-right h-10 px-4 w-auto bg-C4 text-white rounded mx-2 flex items-center">
                        <IoIosUndo size={"1.5em"} />
                        <span className="text-xl ml-2">Voltar</span>
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <p className="w-full">
                        <button
                            type="submit"
                            className="float-right h-10 px-4 w-auto bg-canal_verde text-white rounded mx-2 flex items-center"
                            disabled={loadingChangeStatus}>
                            <IoIosSave size={"1.5em"} />
                            <span className="text-xl ml-2">
                                Salvar {isSubmitting && <ClipLoader size="13" color="white" />}
                            </span>
                        </button>

                        {entity?.status === "CREATED" && (
                            <button
                                onClick={() => {
                                    setLoadingChangeStatus(true);
                                    setLoadingAnalysisLogistics(true);
                                    api.post(
                                        "quote/" + entity?.identifier + "/change-status",
                                        {
                                            status: "review_as_legal",
                                        },
                                        {
                                            headers: {
                                                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                                            },
                                        }
                                    )
                                        .then(response => {
                                            setLoadingChangeStatus(false);
                                            setLoadingAnalysisLogistics(false);
                                            toast.success("Cotação enviada para análise logística com sucesso");
                                            window.location.href = "/cotacao";
                                        })
                                        .catch(error => {
                                            setLoadingChangeStatus(false);
                                            setLoadingAnalysisLogistics(false);
                                        });
                                }}
                                className="float-right h-10 px-4 w-auto bg-roxo_oficial text-white rounded mx-2"
                                disabled={loadingChangeStatus}>
                                <span className="text-xl whitespace-no-wrap">
                                    Solicitar análise logística{" "}
                                    {loadingAnalysisLogistics && <ClipLoader size="13" color="white" />}
                                </span>
                            </button>
                        )}
                        {entity?.status === "ON_REVIEW_LEGAL" && (
                            <button
                                onClick={() => {
                                    if (entity?.expiredDate) {
                                        setLoadingChangeStatus(true);
                                        setLoadingAnalysisOperational(true);
                                        api.post(
                                            "quote/" + entity?.identifier + "/change-status",
                                            {
                                                status: "review_as_operational",
                                            },
                                            {
                                                headers: {
                                                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                                                },
                                            }
                                        )
                                            .then(response => {
                                                setLoadingChangeStatus(false);
                                                setLoadingAnalysisOperational(false);
                                                toast.success("Cotação enviada para análise operacional com sucesso");
                                                window.location.href = "/cotacao";
                                            })
                                            .catch(error => {
                                                setLoadingAnalysisOperational(false);
                                                setLoadingChangeStatus(false);
                                            });
                                    } else {
                                        toast.error("É necessário preencher a validade.");
                                    }
                                }}
                                className="float-right h-10 px-4 w-auto bg-roxo_oficial text-white rounded mx-2"
                                disabled={loadingChangeStatus}>
                                <span className="text-xl whitespace-no-wrap">
                                    Solicitar análise operacional{" "}
                                    {loadingAnalysisOperational && <ClipLoader size="13" color="white" />}
                                </span>
                            </button>
                        )}
                        {entity?.status === "ON_REVIEW_CLIENT" && (
                            <button
                                onClick={() => {
                                    if (clientsOptions.length === 0) {
                                        toast.error("É necessário selecionar um cliente.");
                                    } else {
                                        setLoadingChangeStatus(true);
                                        setLoadingReleaseClient(true);
                                        actionModalClient();
                                    }
                                }}
                                className="float-right h-10 px-4 w-auto bg-roxo_oficial text-white rounded mx-2"
                                disabled={loadingChangeStatus}>
                                <span className="text-xl whitespace-no-wrap">
                                    Liberar para cliente {loadingReleaseClient && <ClipLoader size="13" color="white" />}
                                </span>
                            </button>
                        )}
                        {(entity?.status === "ON_REVIEW_CLIENT" || entity?.status === "ON_REVIEW_OPERATIONAL") && (
                            <>
                                <button
                                    onClick={() => {
                                        setLoadingChangeStatus(true);
                                        setLoadingAproved(true);
                                        actionModalAproved();
                                    }}
                                    className="float-right h-10 px-4 w-auto bg-roxo_oficial text-white rounded mx-2 flex items-center"
                                    disabled={loadingChangeStatus}>
                                    <IoIosCheckmarkCircle size={"1.5em"} />
                                    <span className="text-xl ml-2">
                                        Aprovar {loadingAproved && <ClipLoader size="13" color="white" />}
                                    </span>
                                </button>
                                <button
                                    onClick={() => {
                                        setLoadingChangeStatus(true);
                                        setLoadingReject(true);
                                        actionModalReject();
                                    }}
                                    className="float-right h-10 px-4 w-auto bg-roxo_oficial text-white rounded mx-2 flex items-center"
                                    disabled={loadingChangeStatus}>
                                    <IoIosRemoveCircle size={"1.5em"} />
                                    <span className="text-xl ml-2">
                                        Rejeitar {loadingReject && <ClipLoader size="13" color="white" />}
                                    </span>
                                </button>
                            </>
                        )}
                        {entity?.status === "ON_REVIEW_OPERATIONAL" && (
                            <button
                                onClick={() => {
                                    setLoadingChangeStatus(true);
                                    setLoadingReturn(true);
                                    actionModalReturn();
                                }}
                                className="float-right h-10 px-4 w-auto bg-roxo_oficial text-white rounded mx-2"
                                disabled={loadingChangeStatus}>
                                <span className="text-xl whitespace-no-wrap">
                                    Devolver análise logística {loadingReturn && <ClipLoader size="13" color="white" />}
                                </span>
                            </button>
                        )}
                        <button
                            onClick={() => {
                                setLoadingChangeStatus(true);
                                setLoadingCancell(true);
                                actionModalCancel();
                            }}
                            className="float-right h-10 px-4 w-auto bg-canal_vermelho text-white rounded mx-2 flex items-center"
                            disabled={loadingChangeStatus}>
                            <IoIosCloseCircle size={"1.5em"} />
                            <span className="text-xl ml-2">
                                Cancelar {loadingCancell && <ClipLoader size="13" color="white" />}
                            </span>
                        </button>
                        <button
                            onClick={() => {
                                setLoadingChangeStatus(true);
                                window.location.href = "/cotacao";
                            }}
                            disabled={loadingChangeStatus}
                            className="float-right h-10 px-4 w-auto bg-C4 text-white rounded mx-2 flex items-center">
                            <IoIosUndo size={"1.5em"} />
                            <span className="text-xl ml-2">Voltar</span>
                        </button>
                    </p>
                </>
            );
        }
    };

    // const CustomInputComponent = entity => <input className="my-custom-input" type="text" {...entity} />;

    const downloadAllFiles = useCallback(() => {
        setDownloadingAll(true);

        let link = document.createElement("a");

        link.href = (requests.getApiUrl() + `/open/quote/file/${entity.identifier}/all/download`).replace("//open", "/open");
        link.target = "_blank";
        link.download = entity.identifier + ".zip";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadingAll(false);
    });

    const loadList = () => {
        requests.getFilesListByQuote(entity.identifier, meta?.current_page).then(response => {
            setData(response.data);
            setMeta(response.meta);
            setLoading(false);
        });
    };

    const downloadFile = useCallback((identifier, filename, ext) => {
        setDownloadingFile(identifier);

        let link = document.createElement("a");

        link.href = (requests.getApiUrl() + `/open/quote/file/${entity.identifier}/${identifier}/download`).replace(
            "//open",
            "/open"
        );
        link.target = "_blank";
        link.download = filename + "." + ext;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadingFile("");
    });

    const actionModalShow = (identifier, fileIdentifier, name) => {
        setCurrentEntity({
            identifier: identifier,
            fileIdentifier: fileIdentifier,
            name: name,
        });

        setOpenModalShow(!openModalShow);
    };

    const closeModalShow = () => {
        setOpenModalShow(false);
    };

    const actionModalRemove = (identifier, entity) => {
        setCurrentEntity({
            ...entity,
            quoteIdentifier: identifier,
        });
        setOpenModalRemove(!openModalRemove);
    };

    const closeModalRemove = () => {
        setOpenModalRemove(false);
    };

    const actionModalCancel = () => {
        setCurrentEntity(entity);
        setOpenModalCancel(!openModalCancel);
    };

    const closeModalCancel = () => {
        setOpenModalCancel(false);
        setLoadingCancell(false);
        setLoadingChangeStatus(false);
    };

    const actionModalReturn = () => {
        setOpenModalReturn(!openModalReturn);
    };

    const closeModalReturn = () => {
        setOpenModalReturn(false);
        setLoadingReturn(false);
        setLoadingChangeStatus(false);
    };

    const actionModalClient = () => {
        setOpenModalClient(!openModalClient);
    };

    const closeModalClient = () => {
        setOpenModalClient(false);
        setLoadingChangeStatus(false);
        setLoadingReleaseClient(false);
    };

    const actionModalAproved = (identifier, entity) => {
        setCurrentEntity({
            ...entity,
            quoteIdentifier: identifier,
        });
        setOpenModalAproved(!openModalAproved);
    };

    const closeModalAproved = () => {
        setOpenModalAproved(false);
        setLoadingAproved(false);
        setLoadingChangeStatus(false);
    };

    const actionModalReject = (identifier, entity) => {
        setCurrentEntity({
            ...entity,
            quoteIdentifier: identifier,
        });
        setOpenModalReject(!openModalReject);
    };

    const closeModalReject = () => {
        setOpenModalReject(false);
        setLoadingReject(false);
        setLoadingChangeStatus(false);
    };

    useEffect(() => {
        loadClientsList("", { identifier: entity?.clientIdentifier, name: entity?.clientName, documentNumber: entity?.clientDocumentNumber });
        loadProcessList(entity?.clientIdentifier);

        api.get("transport-modality/list", {
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
        }).then(response => {
            var modalities = [];

            response.data.data.forEach(item => {
                if (item?.slug === "ground") {
                    setTerrestreValue(item?.identifier);
                } else if (item?.slug === "sea") {
                    setMaritimoValue(item?.identifier);
                } else if (item?.slug === "air") {
                    setAereoValue(item?.identifier);
                }

                if (item?.slug !== "ground") {
                    modalities.push({
                        value: item?.identifier,
                        label: item?.description,
                    });
                }
            });

            setModalityOptions(modalities);
        });

        api.get("port/list?page_size=10000", {
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
        }).then(response => {
            var ports = [];

            response.data.data.forEach(item => {
                ports.push({
                    value: item?.id,
                    label: item?.name + (item?.country ? " (" + item?.country.name + ")" : ""),
                });
            });

            setPortsOptions(ports);
        });
    }, []);

    useEffect(() => {
        setDisabledForm(false);
        if (["APPROVED", "REJECTED", "CANCELLED"].includes(entity?.status)) {
            setDisabledForm(true);
        }
    }, [entity?.quote]);

    return (
        <Layout>
            <SEO title="Cotação" />
            <ModalRemoveFile
                actionModal={closeModalRemove}
                openModal={openModalRemove}
                entity={currentEntity}
                handleSuccess={() => {
                    closeModalRemove();
                    loadList();
                }}
            />
            <ModalShowFile
                actionModal={closeModalShow}
                openModal={openModalShow}
                entity={currentEntity}
                handleSuccess={() => {
                    closeModalShow();
                }}
            />
            <ModalCancel
                actionModal={closeModalCancel}
                openModal={openModalCancel}
                entity={currentEntity}
                setLoadingChangeStatus={setLoadingChangeStatus}
                setLoadingCancell={setLoadingCancell}
                setQuoteStatus={setQuoteStatus}
                setDisabledForm={setDisabledForm}
            />
            <ModalReturn
                actionModal={closeModalReturn}
                openModal={openModalReturn}
                entity={entity}
                setLoadingChangeStatus={setLoadingChangeStatus}
                handleSuccess={() => {
                    closeModalReturn();
                    window.location.href = "/cotacao";
                }}
            />
            <ModalClient
                actionModal={closeModalClient}
                openModal={openModalClient}
                entity={entity}
                client={clientsOptions}
                setLoadingChangeStatus={setLoadingChangeStatus}
                handleSuccess={() => {
                    closeModalClient();
                }}
            />
            <ModalAproved
                actionModal={closeModalAproved}
                openModal={openModalAproved}
                entity={entity}
                setLoadingChangeStatus={setLoadingChangeStatus}
                setLoadingAproved={setLoadingAproved}
                setQuoteStatus={setQuoteStatus}
                setDisabledForm={setDisabledForm}
            />
            <ModalRejected
                actionModal={closeModalReject}
                openModal={openModalReject}
                entity={entity}
                setLoadingChangeStatus={setLoadingChangeStatus}
                setLoadingReject={setLoadingReject}
                setQuoteStatus={setQuoteStatus}
                setDisabledForm={setDisabledForm}
            />
            <div className="p-4">
                <Card className="mb-5">
                    <TitleC6>COTAÇÃO - EDIÇÃO</TitleC6>
                    <div className="border-t border-C2 border-solid pt-4 pb-12">
                        <Formik
                            initialValues={{
                                availableDate: entity?.availableDate,
                                reference: entity?.reference,
                                client: entity?.clientIdentifier,
                                process: entity?.processIdentifier,
                                modality: entity?.transportModalityIdentifier,
                                originPort: entity?.originPortId,
                                destinationPort: entity?.destinyPortId,
                                incoterm: entity?.incoterm,
                                inlandZipCode: entity?.inlandZipCode,
                                quoteType: entity?.quoteType,
                                dangerousClass: entity?.dangerousClass,
                                quantityFormatted: entity?.quantity,
                                quantity: entity?.quantity,
                                heightFormatted: entity?.height,
                                height: entity?.height,
                                widthFormatted: entity?.width,
                                width: entity?.width,
                                lengthFormatted: entity?.length,
                                length: entity?.length,
                                cbmCubicMetersFormatted: entity?.cbmCubicMeters,
                                cbmCubicMeters: entity?.cbmCubicMeters,
                                weigthFormatted: entity?.weigth,
                                weigth: entity?.weigth,
                                ctrs: entity?.ctrs,
                                description: entity?.description,
                                invoiceValueFormatted: entity?.invoiceValue,
                                invoiceValue: entity?.invoiceValue,
                                isPacklistReceived: entity?.isPacklistReceived,
                                expiredDate: entity?.expiredDate,
                                vehicle: entity?.vehicle,
                                vehicleType: entity?.vehicleType,
                                package: entity?.package,
                                packegeQtt: entity?.packegeQtt,
                                packageWeight: entity?.packageWeight,
                                packegeCubicMeters: entity?.packegeCubicMeters,
                                packageValue: entity?.packageValue,
                                gris: entity?.gris,
                                icms: entity?.icms,
                                others: entity?.others,
                                adValorem: entity?.adValorem,
                                quoteObservations: entity?.quoteObservations,
                                valueFormatted: entity?.value,
                                value: entity?.value,
                                transitTime: entity?.transitTime,
                                freeTime: entity?.freeTime,
                                originTaxes: entity?.originTaxes,
                                freight: entity?.freight,
                                copyPeople3Email: entity?.secondEmailCopyEmail,
                                copyPeople2: entity?.firstUserEmailCopyIdentifier,
                            }}
                            validationSchema={yup.object().shape({
                                availableDate: yup.string().nullable(),
                                reference: yup.string().required("Campo obrigatório"),
                                client:
                                    entity?.status === "ON_REVIEW_CLIENT" && clientsOptions.length === 0
                                        ? yup.string().required("Campo obrigatório")
                                        : yup.string(),
                                process:
                                    clientsOptions.length > 0 ? yup.string().required("Campo obrigatório") : yup.string(),
                                modality: yup.string().required("Campo obrigatório"),
                                originPort: yup.string().required("Campo obrigatório"),
                                destinationPort: yup.string().required("Campo obrigatório"),
                                incoterm: yup.string(),
                                inlandZipCode:
                                    requiredList?.incoterm === "EXW"
                                        ? yup.string().required("Campo obrigatório")
                                        : yup.string(),
                                quantityFormatted:
                                    requiredList?.modality === "Aéreo"
                                        ? yup.string().required("Campo obrigatório")
                                        : yup.string(),
                                heightFormatted:
                                    requiredList?.modality === "Aéreo"
                                        ? yup.string().required("Campo obrigatório")
                                        : yup.string(),
                                widthFormatted:
                                    requiredList?.modality === "Aéreo"
                                        ? yup.string().required("Campo obrigatório")
                                        : yup.string(),
                                lengthFormatted:
                                    requiredList?.modality === "Aéreo"
                                        ? yup.string().required("Campo obrigatório")
                                        : yup.string(),
                                cbmCubicMetersFormatted:
                                    requiredList?.modality === "Aéreo"
                                        ? yup.string().required("Campo obrigatório")
                                        : yup.string(),
                                weigthFormatted:
                                    requiredList?.modality === "Aéreo"
                                        ? yup.string().required("Campo obrigatório")
                                        : yup.string(),
                                ctrs: yup.array().of(
                                    yup.object().shape({
                                        qtt:
                                            requiredList?.modality === "Marítimo" && ctrsMandatory
                                                ? yup.string().required("Campo obrigatório")
                                                : yup
                                                    .number()
                                                    .integer("Apenas inteiro")
                                                    .min(0, "Números positivos"),
                                        size:
                                            requiredList?.modality === "Marítimo" && ctrsMandatory
                                                ? yup.string().required("Campo obrigatório")
                                                : yup
                                                    .string()
                                                    .when("qtt", (qtt, field) =>
                                                        qtt > 0 ? yup.string().required("Campo obrigatório") : field
                                                    ),
                                        type:
                                            requiredList?.modality === "Marítimo" && ctrsMandatory
                                                ? yup.string().required("Campo obrigatório")
                                                : yup
                                                    .string()
                                                    .when("qtt", (qtt, field) =>
                                                        qtt > 0 ? yup.string().required("Campo obrigatório") : field
                                                    ),
                                    })
                                ),
                                description: yup.string(),
                                invoiceValueFormatted: yup.string(),
                                quoteType: yup.string(),
                                dangerousClass: yup.string(),
                                expiredDate: yup.string().nullable().required("Campo obrigatório"),
                                vehicle: yup.string(),
                                vehicleType: yup.string(),
                                package: yup.string(),
                                packegeQtt: yup.string(),
                                packageWeight: yup.string(),
                                packegeCubicMeters: yup.string(),
                                packageValue: yup.string(),
                                gris: yup.string(),
                                icms: yup.string(),
                                others: yup.string(),
                                adValorem: yup.string(),
                                quoteObservations: yup.string(),

                                valueFormatted: yup.string(),
                                transitTime: yup.string(),
                                freeTime: yup.string(),
                                originTaxes: yup.string(),
                                freight: yup.string(),
                            })}
                            onSubmit={(values, { resetForm, setValues, setFieldError, setSubmitting }) => {
                                setSubmitting(true);
                                setLoadingChangeStatus(true);
                                requests
                                    .getQuoteEdit(
                                        entity?.identifier,
                                        values,
                                        entity?.meta?.current_page > 0 ? entity?.meta?.current_page : 1
                                    )
                                    .then(response => {
                                        window.location.href = "/cotacao";
                                    })
                                    .catch(error => {
                                        setSubmitting(false);
                                        setLoadingChangeStatus(false);
                                    });
                            }}>
                            {({
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                errors,
                                values,
                                touched,
                                handleBlur,
                                setFieldValue,
                                setValues,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <h5 className="mx-2 font-bold">SOLICITAÇÃO</h5>
                                    <div className="flex mb-5">
                                        <div className="w-2/12 px-2">
                                            <label className="text-gray" htmlFor="name">
                                                Código da cotação:&nbsp;
                                            </label>
                                            <span className="font-bold">{entity?.name}</span>
                                        </div>
                                        <div className="w-2/12 px-2">
                                            <label htmlFor="availableDate">Carga disponível</label>
                                            <br />
                                            <DatePicker
                                                locale="pt"
                                                selected={values.availableDate && new Date(values.availableDate)}
                                                disabled={disabledForm}
                                                dateFormat="dd/MM/yyyy"
                                                className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                name="availableDate"
                                                onChange={date => {
                                                    setFieldValue("availableDate", date);
                                                }}
                                                placeholderText="Selecione a carga disponível"
                                            />
                                            <ErrorMessage
                                                component="p"
                                                className="text-red mb-4 font-light text-xs"
                                                name="availableDate"
                                            />
                                        </div>
                                        <div className="w-2/12 px-2">
                                            <label htmlFor="reference">
                                                REF <span className="text-red">*</span>
                                            </label>
                                            <Field
                                                type="text"
                                                className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                name="reference"
                                                id="reference"
                                                onChange={handleChange}
                                                value={values.reference}
                                                disabled={disabledForm}
                                                placeholder="Preencha a referência"
                                            />

                                            <ErrorMessage
                                                component="p"
                                                className="text-red mb-4 font-light text-xs"
                                                name="reference"
                                            />
                                        </div>
                                        <div className="w-2/12 px-2">
                                            <label htmlFor="client">Cliente</label>
                                            <Select
                                                options={clientsOptions}
                                                id="client"
                                                name="client"
                                                value={clientsOptions.filter(option => option.value === values.client)}
                                                isDisabled={disabledForm}
                                                onChange={item => {
                                                    setClientChanged(true);
                                                    setFieldValue("client", item?.value);
                                                    loadProcessList(item?.value);
                                                    setFieldValue("process", null);
                                                }}
                                                noOptionsMessage={() => noClientOptionsMessage}
                                                onInputChange={inputValue => {
                                                    loadClientsList(inputValue);
                                                }}
                                                isLoading={isLoadingClients}
                                                className="outline-none transform-none"
                                                placeholder="Selecione o cliente"

                                            />
                                            <ErrorMessage
                                                component="p"
                                                className="text-red mb-4 font-light text-xs"
                                                name="client"
                                            />
                                        </div>
                                        <div className="w-2/12 px-2">
                                            <label htmlFor="process">
                                                Processo {clientsOptions.length > 0 && <span className="text-red">*</span>}
                                            </label>
                                            <Select
                                                options={processOptions}
                                                id="process"
                                                name="process"
                                                value={processOptions.filter(option => option.value === values.process)}
                                                onInputChange={inputValue => {
                                                    loadProcessList(values.client, inputValue);
                                                }}
                                                isLoading={isLoadingProcess}
                                                noOptionsMessage={() => noOptionsMessage}
                                                onChange={item => setFieldValue("process", item?.value)}
                                                className="outline-none transform-none"
                                                isDisabled={disabledForm}
                                                placeholder="Selecione o processo"
                                            />
                                            <ErrorMessage
                                                component="p"
                                                className="text-red mb-4 font-light text-xs"
                                                name="process"
                                            />
                                        </div>
                                        <div className="w-2/12 px-2">
                                            <label htmlFor="modality" className="block mb-1">
                                                Modal <span className="text-red">*</span>
                                            </label>
                                            <Select
                                                options={modalityOptions}
                                                id="modality"
                                                name="modality"
                                                value={modalityOptions.filter(option => option.value === values.modality)}
                                                isDisabled={disabledForm}
                                                onChange={item => setFieldValue("modality", item?.value)}
                                                className="outline-none transform-none"
                                                placeholder="Selecione a modal"
                                            />
                                            <ErrorMessage
                                                component="p"
                                                className="text-red mb-4 font-light text-xs"
                                                name="modality"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex mb-5">
                                        <div className="w-6/12 px-2">
                                            <label htmlFor="originPort">
                                                Origem <span className="text-red">*</span>
                                            </label>
                                            <Select
                                                options={portsOptions}
                                                id="originPort"
                                                name="originPort"
                                                isDisabled={disabledForm}
                                                value={portsOptions.filter(option => option.value === values.originPort)}
                                                onChange={item => setFieldValue("originPort", item?.value)}
                                                className="outline-none transform-none"
                                                placeholder="Selecione a origem"
                                            />
                                            <ErrorMessage
                                                component="p"
                                                className="text-red mb-4 font-light text-xs"
                                                name="originPort"
                                            />
                                        </div>
                                        <div className="w-6/12 px-2">
                                            <label htmlFor="destinationPort">
                                                Destino <span className="text-red">*</span>
                                            </label>
                                            <Select
                                                options={portsOptions}
                                                id="destinationPort"
                                                name="destinationPort"
                                                isDisabled={disabledForm}
                                                value={portsOptions.filter(option => option.value === values.destinationPort)}
                                                onChange={item => setFieldValue("destinationPort", item?.value)}
                                                className="outline-none transform-none"
                                                placeholder="Selecione o destino"
                                            />
                                            <ErrorMessage
                                                component="p"
                                                className="text-red mb-4 font-light text-xs"
                                                name="destinationPort"
                                            />
                                        </div>
                                    </div>
                                    {values.modality !== terrestreValue && (
                                        <div className="flex mb-5">
                                            <div className="w-6/12 px-2">
                                                <label htmlFor="incoterm">Incoterm</label>
                                                <Select
                                                    id="incoterm"
                                                    name="incoterm"
                                                    options={incotermOptions}
                                                    value={incotermOptions.filter(option => option.value === values.incoterm)}
                                                    isDisabled={disabledForm}
                                                    onChange={item => {
                                                        setFieldValue("incoterm", item?.value);
                                                        setRequiredList({
                                                            ...requiredList,
                                                            incoterm: item?.value,
                                                        });
                                                    }}
                                                    placeholder="Selecione o inconterm"
                                                />
                                            </div>
                                            <div className="w-4/12 px-2">
                                                <label htmlFor="inlandZipCode">
                                                    Inland Zip Code{" "}
                                                    <span className="text-red">{values.incoterm === "EXW" && "*"}</span>
                                                </label>
                                                <Field
                                                    type="text"
                                                    className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                    name="inlandZipCode"
                                                    id="inlandZipCode"
                                                    onChange={handleChange}
                                                    value={values.inlandZipCode}
                                                    disabled={disabledForm}
                                                    placeholder="Preencha o Inland zip code"
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red mb-4 font-light text-xs"
                                                    name="inlandZipCode"
                                                />
                                            </div>
                                            {values.modality === maritimoValue && (
                                                <div className="w-2/12 px-2">
                                                    <label htmlFor="quoteType">Tipo</label>
                                                    <Select
                                                        id="quoteType"
                                                        name="quoteType"
                                                        options={quoteTypeOptions}
                                                        value={quoteTypeOptions.filter(
                                                            option => option.value === values.quoteType
                                                        )}
                                                        isDisabled={disabledForm}
                                                        onChange={item => {
                                                            setFieldValue("quoteType", item?.value);

                                                            if (item?.value === "LCL") {
                                                                setCtrsMandatory(false);
                                                            } else {
                                                                setCtrsMandatory(true);
                                                            }
                                                        }}
                                                        className="outline-none transform-none"
                                                        placeholder="Selecione o tipo"
                                                    />
                                                </div>
                                            )}
                                            <div className="w-4/12 px-2">
                                                <label htmlFor="dangerousClass" className="block mb-1">
                                                    Classe
                                                </label>
                                                <Select
                                                    id="dangerousClass"
                                                    name="dangerousClass"
                                                    options={dangerousClassOptions}
                                                    value={dangerousClassOptions.filter(
                                                        option => option.value === values.dangerousClass
                                                    )}
                                                    isDisabled={disabledForm}
                                                    onChange={item => setFieldValue("dangerousClass", item?.value)}
                                                    placeholder="Selecione a classe"
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red mb-4 font-light text-xs"
                                                    name="dangerousClass"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex xs:flex-wrap lg:flex-no-wrap mb-5">
                                        <div className="flex w-full lg:w-full">
                                            <div className="xs:mb-4 lg:mb-0 xs:w-1/2 md:w-1/6 lg:w-2/12 px-2">
                                                <label htmlFor="quantityFormatted">
                                                    Quantidade{" "}
                                                    <span className="text-red">{values.modality === "Aéreo" && "*"}</span>
                                                </label>
                                                <NumberFormat
                                                    className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                    name="quantityFormatted"
                                                    id="quantityFormatted"
                                                    disabled={disabledForm}
                                                    thousandsGroupStyle="thousand"
                                                    thousandSeparator="."
                                                    value={values.quantityFormatted}
                                                    decimalSeparator=","
                                                    displayType="input"
                                                    type="text"
                                                    allowNegative={true}
                                                    decimalScale={false}
                                                    fixedDecimalScale={true}
                                                    onValueChange={values => {
                                                        const { formattedValue, value } = values;
                                                        setFieldValue("quantityFormatted", formattedValue);
                                                        setFieldValue("quantity", value);
                                                    }}
                                                    placeholder="Preencha a quantidade"
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red mb-4 font-light text-xs"
                                                    name="quantity"
                                                />
                                            </div>
                                            <div className="xs:mb-4 lg:mb-0 xs:w-1/2 md:w-1/6 lg:w-2/12 px-2">
                                                <label htmlFor="heightFormatted">
                                                    Altura{" "}
                                                    <span className="text-red">{values.modality === "Aéreo" && "*"}</span>
                                                </label>
                                                <NumberFormat
                                                    className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                    name="heightFormatted"
                                                    id="heightFormatted"
                                                    disabled={disabledForm}
                                                    thousandsGroupStyle="thousand"
                                                    thousandSeparator="."
                                                    value={values.heightFormatted}
                                                    decimalSeparator=","
                                                    displayType="input"
                                                    type="text"
                                                    allowNegative={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    onValueChange={values => {
                                                        const { formattedValue, value } = values;
                                                        setFieldValue("heightFormatted", formattedValue);
                                                        setFieldValue("height", value);
                                                    }}
                                                    placeholder="Preencha a altura"
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red mb-4 font-light text-xs"
                                                    name="height"
                                                />
                                            </div>
                                            <div className="xs:mb-4 lg:mb-0 xs:w-1/2 md:w-1/6 lg:w-2/12 px-2">
                                                <label htmlFor="widthFormatted">
                                                    Largura{" "}
                                                    <span className="text-red">{values.modality === "Aéreo" && "*"}</span>
                                                </label>
                                                <NumberFormat
                                                    className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                    name="widthFormatted"
                                                    id="widthFormatted"
                                                    disabled={disabledForm}
                                                    thousandsGroupStyle="thousand"
                                                    thousandSeparator="."
                                                    value={values.widthFormatted}
                                                    decimalSeparator=","
                                                    displayType="input"
                                                    type="text"
                                                    allowNegative={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    onValueChange={values => {
                                                        const { formattedValue, value } = values;
                                                        setFieldValue("widthFormatted", formattedValue);
                                                        setFieldValue("width", value);
                                                    }}
                                                    placeholder="Preencha a largura"
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red mb-4 font-light text-xs"
                                                    name="width"
                                                />
                                            </div>
                                            <div className="xs:mb-4 lg:mb-0 xs:w-1/2 md:w-1/6 lg:w-2/12 px-2">
                                                <label htmlFor="lengthFormatted">
                                                    Comprimento{" "}
                                                    <span className="text-red">{values.modality === "Aéreo" && "*"}</span>
                                                </label>
                                                <NumberFormat
                                                    className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                    name="lengthFormatted"
                                                    id="lengthFormatted"
                                                    disabled={disabledForm}
                                                    thousandsGroupStyle="thousand"
                                                    thousandSeparator="."
                                                    value={values.lengthFormatted}
                                                    decimalSeparator=","
                                                    displayType="input"
                                                    type="text"
                                                    allowNegative={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    onValueChange={values => {
                                                        const { formattedValue, value } = values;
                                                        setFieldValue("lengthFormatted", formattedValue);
                                                        setFieldValue("length", value);
                                                    }}
                                                    placeholder="Preencha o comprimento"
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red mb-4 font-light text-xs"
                                                    name="length"
                                                />
                                            </div>
                                            <div className="xs:mb-4 lg:mb-0 xs:w-1/2 md:w-1/6 lg:w-2/12 px-2">
                                                <label htmlFor="cbmCubicMetersFormatted">
                                                    CBM M3{" "}
                                                    <span className="text-red">{values.modality === "Aéreo" && "*"}</span>
                                                </label>
                                                <NumberFormat
                                                    className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                    name="cbmCubicMetersFormatted"
                                                    id="cbmCubicMetersFormatted"
                                                    disabled={disabledForm}
                                                    thousandsGroupStyle="thousand"
                                                    thousandSeparator="."
                                                    value={values.cbmCubicMetersFormatted}
                                                    decimalSeparator=","
                                                    displayType="input"
                                                    type="text"
                                                    allowNegative={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    onValueChange={values => {
                                                        const { formattedValue, value } = values;
                                                        setFieldValue("cbmCubicMetersFormatted", formattedValue);
                                                        setFieldValue("cbmCubicMeters", value);
                                                    }}
                                                    placeholder="Preencha o CBM M3"
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red mb-4 font-light text-xs"
                                                    name="cbmCubicMeters"
                                                />
                                            </div>
                                            <div className="xs:mb-4 lg:mb-0 xs:w-1/2 md:w-1/6 lg:w-2/12 px-2">
                                                <label htmlFor="weigthFormatted">
                                                    Peso Bruto KG{" "}
                                                    <span className="text-red">{values.modality === "Aéreo" && "*"}</span>
                                                </label>
                                                <NumberFormat
                                                    className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                    name="weigthFormatted"
                                                    id="weigthFormatted"
                                                    disabled={disabledForm}
                                                    thousandsGroupStyle="thousand"
                                                    thousandSeparator="."
                                                    value={values.weigthFormatted}
                                                    decimalSeparator=","
                                                    displayType="input"
                                                    type="text"
                                                    allowNegative={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    onValueChange={values => {
                                                        const { formattedValue, value } = values;
                                                        setFieldValue("weigthFormatted", formattedValue);
                                                        setFieldValue("weigth", value);
                                                    }}
                                                    placeholder="Preencha o peso bruto"
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red mb-4 font-light text-xs"
                                                    name="weigth"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full px-2">
                                        <label htmlFor="description">Observações</label>
                                        <Field
                                            as="textarea"
                                            rows="6"
                                            className="w-full rounded-md border py-1 px-2 border-C2"
                                            name="description"
                                            id="description"
                                            onChange={handleChange}
                                            value={values.description}
                                            disabled={disabledForm}
                                            placeholder="Opcional"
                                        />
                                        <ErrorMessage
                                            component="p"
                                            className="text-red mb-4 font-light text-xs"
                                            name="description"
                                        />
                                    </div>

                                    <div className="flex xs:flex-wrap lg:flex-no-wrap mb-5">
                                        <div className="w-1/5 px-2">
                                            <label htmlFor="invoiceValueFormatted">Valor Invoice</label>
                                            <NumberFormat
                                                className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                name="invoiceValueFormatted"
                                                id="invoiceValueFormatted"
                                                disabled={disabledForm}
                                                thousandsGroupStyle="thousand"
                                                thousandSeparator="."
                                                value={values.invoiceValueFormatted}
                                                decimalSeparator=","
                                                displayType="input"
                                                type="text"
                                                allowNegative={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                onValueChange={values => {
                                                    const { formattedValue, value } = values;
                                                    setFieldValue("invoiceValueFormatted", formattedValue);
                                                    setFieldValue("invoiceValue", value);
                                                }}
                                                placeholder="Preencha o valor da invoice"
                                            />
                                            <ErrorMessage
                                                component="p"
                                                className="text-red mb-4 font-light text-xs"
                                                name="invoiceValueFormatted"
                                            />
                                        </div>
                                        <div className="w-1/5 px-2 pt-8">
                                            <label>
                                                <Field
                                                    type="checkbox"
                                                    name="isPacklistReceived"
                                                    id="isPacklistReceived"
                                                    onChange={handleChange}
                                                    disabled={disabledForm}
                                                />
                                                <span className="check-box-effect"></span>
                                                Packlist Recebido
                                            </label>
                                        </div>
                                        {entity?.secondEmailCopyEmail &&
                                            <div className="w-2/5 px-2">
                                                <label htmlFor="originPort">Contato 1 - Cópia (E-mail)</label>
                                                <Field
                                                    type="email"
                                                    id="secondEmailCopyEmail"
                                                    name="secondEmailCopyEmail"
                                                    className="w-full rounded-md border py-1 px-5 border-C2 h-10"
                                                    disabled={true}
                                                    value={entity?.secondEmailCopyEmail}
                                                />
                                            </div>
                                        }

                                        {entity?.firstUserEmailCopyName &&
                                            <div className="w-1/5 px-2">
                                                <label htmlFor="originPort">Contato 2 - Cópia</label>
                                                <Field
                                                    type="text"
                                                    id="firstUserEmailCopyName"
                                                    name="firstUserEmailCopyName"
                                                    className="w-full rounded-md border py-1 px-5 border-C2 h-10"
                                                    disabled={true}
                                                    value={entity?.firstUserEmailCopyName}
                                                />
                                            </div>
                                        }
                                    </div>

                                    <hr />

                                    <h5 className="mx-2 my-4 font-bold">COTAÇÃO</h5>

                                    {values.modality === aereoValue && (
                                        <>
                                            <div className="flex mb-5">
                                                <div className="w-full md:w-1/3 px-2">
                                                    <label htmlFor="valueFormatted">Valor</label>
                                                    <NumberFormat
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="valueFormatted"
                                                        id="valueFormatted"
                                                        disabled={disabledForm}
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator="."
                                                        value={values.valueFormatted}
                                                        decimalSeparator=","
                                                        displayType="input"
                                                        type="text"
                                                        allowNegative={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        onValueChange={values => {
                                                            const { formattedValue, value } = values;
                                                            setFieldValue("valueFormatted", formattedValue);
                                                            setFieldValue("value", value);
                                                        }}
                                                        placeholder="Preencha o valor"
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="value"
                                                    />
                                                </div>
                                                <div className="w-full md:w-1/3 px-2">
                                                    <label htmlFor="freight">Frete</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="freight"
                                                        id="freight"
                                                        onChange={handleChange}
                                                        value={values.freight}
                                                        disabled={disabledForm}
                                                        placeholder="Preencha o frete"
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="freight"
                                                    />
                                                </div>
                                                <div className="w-full md:w-1/3 px-2">
                                                    <label htmlFor="expiredDate">
                                                        Validade <span className="text-red">*</span>
                                                    </label>
                                                    <br />
                                                    <DatePicker
                                                        locale="pt"
                                                        selected={values.expiredDate && new Date(values.expiredDate)}
                                                        disabled={disabledForm}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="expiredDate"
                                                        onChange={date => {
                                                            setFieldValue("expiredDate", date);
                                                        }}
                                                        placeholderText="Selecione a validade"
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="expiredDate"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <label htmlFor="quoteObservations">Observações</label>
                                                <Field
                                                    as="textarea"
                                                    rows="6"
                                                    className="w-full rounded-md border py-1 px-2 border-C2"
                                                    name="quoteObservations"
                                                    id="quoteObservations"
                                                    onChange={handleChange}
                                                    value={values.quoteObservations}
                                                    disabled={disabledForm}
                                                    placeholder="Opcional"
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red mb-4 font-light text-xs"
                                                    name="quoteObservations"
                                                />
                                            </div>
                                        </>
                                    )}
                                    {values.modality === maritimoValue && (
                                        <>
                                            <div className="flex xs:flex-wrap lg:flex-no-wrap mb-5">
                                                <div className="w-full md:w-1/2">
                                                    {values.ctrs?.map((ctr, index) => (
                                                        <article key={index}>
                                                            <div className="flex w-full">
                                                                <div className="w-full md:w-2/12 text-center mt-6">
                                                                    {index === 0 ? (
                                                                        <button
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                if (disabledForm) {
                                                                                    return;
                                                                                }
                                                                                values.ctrs.push({
                                                                                    qtt: "",
                                                                                    size: "",
                                                                                    type: "",
                                                                                });
                                                                                setFieldValue("ctrs", values.ctrs);
                                                                            }}>
                                                                            <span className="text-4xl text-center">
                                                                                <FiPlus color="#422C76" />
                                                                            </span>
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                if (disabledForm) {
                                                                                    return;
                                                                                }
                                                                                values.ctrs.splice(index, 1);
                                                                                setFieldValue("ctrs", values.ctrs);
                                                                            }}>
                                                                            <span className="text-4xl">
                                                                                <FiX color="#422C76" />
                                                                            </span>
                                                                        </button>
                                                                    )}
                                                                </div>

                                                                <div className="w-full md:w-3/12 mx-2">
                                                                    <label htmlFor="qttCtr">
                                                                        Quantidade CTR{" "}
                                                                        <span className="text-red">
                                                                            {requiredList.modality === "Marítimo" && values.quoteType !== "LCL" && "*"}
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                                        value={values.ctrs[index].qtt}
                                                                        disabled={disabledForm}
                                                                        onChange={e => {
                                                                            values.ctrs[index].qtt = e.target.value;
                                                                            setFieldValue("ctrs", values.ctrs);
                                                                        }}
                                                                        placeholder="Preencha a quantidade CTR"
                                                                    />
                                                                    <ErrorMessage
                                                                        component="p"
                                                                        className="text-red mb-4 font-light text-xs"
                                                                        name={`ctrs[${index}].qtt`}
                                                                    />
                                                                </div>
                                                                <div className="w-full md:w-3/12 mx-2">
                                                                    <label htmlFor="sizeCtr">
                                                                        Tamanho{" "}
                                                                        <span className="text-red">
                                                                            {requiredList.modality === "Marítimo" && values.quoteType !== "LCL" && "*"}
                                                                        </span>
                                                                    </label>
                                                                    <Select
                                                                        options={ctrTamanhoOptions}
                                                                        value={ctrTamanhoOptions.filter(
                                                                            option => option.value === values.ctrs[index].size
                                                                        )}
                                                                        onChange={select => {
                                                                            values.ctrs[index].size = select?.value;
                                                                            setFieldValue("ctrs", values.ctrs);
                                                                        }}
                                                                        isDisabled={disabledForm}
                                                                        className="outline-none transform-none"
                                                                        placeholder="Preencha o tamanho"
                                                                    />
                                                                    <ErrorMessage
                                                                        component="p"
                                                                        className="text-red mb-4 font-light text-xs"
                                                                        name={`ctrs[${index}].size`}
                                                                    />
                                                                </div>
                                                                <div className="w-full md:w-4/12 mx-2">
                                                                    <label htmlFor="typeCtr">
                                                                        Tipo CTR{" "}
                                                                        <span className="text-red">
                                                                            {requiredList.modality === "Marítimo" && values.quoteType !== "LCL" && "*"}
                                                                        </span>
                                                                    </label>
                                                                    <Select
                                                                        options={ctrTypeOptions}
                                                                        value={ctrTypeOptions.filter(
                                                                            option => option.value === values.ctrs[index].type
                                                                        )}
                                                                        onChange={select => {
                                                                            values.ctrs[index].type = select?.value;
                                                                            setFieldValue("ctrs", values.ctrs);
                                                                        }}
                                                                        isDisabled={disabledForm}
                                                                        className="outline-none transform-none"
                                                                        placeholder="Preencha o tipo CTR"
                                                                    />
                                                                    <ErrorMessage
                                                                        component="p"
                                                                        className="text-red mb-4 font-light text-xs"
                                                                        name={`ctrs[${index}].type`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </article>
                                                    ))}
                                                </div>

                                                <div className="w-full md:w-1/2">
                                                    <label htmlFor="quoteObservations">Observações</label>
                                                    <Field
                                                        as="textarea"
                                                        rows="6"
                                                        className="w-full rounded-md border py-1 px-2 border-C2"
                                                        name="quoteObservations"
                                                        id="quoteObservations"
                                                        onChange={handleChange}
                                                        value={values.quoteObservations}
                                                        disabled={disabledForm}
                                                        placeholder="Opcional"
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="quoteObservations"
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex mb-5">
                                                <div className="w-full md:w-1/4 px-2">
                                                    <label htmlFor="valueFormatted">Valor</label>
                                                    <NumberFormat
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="valueFormatted"
                                                        id="valueFormatted"
                                                        disabled={disabledForm}
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator="."
                                                        value={values.valueFormatted}
                                                        decimalSeparator=","
                                                        displayType="input"
                                                        type="text"
                                                        allowNegative={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        onValueChange={values => {
                                                            const { formattedValue, value } = values;
                                                            setFieldValue("valueFormatted", formattedValue);
                                                            setFieldValue("value", value);
                                                        }}
                                                        placeholder="Preencha o valor"
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="value"
                                                    />
                                                </div>
                                                <div className="w-full md:w-1/4 px-2">
                                                    <label htmlFor="transitTime">Transit Time</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="transitTime"
                                                        id="transitTime"
                                                        onChange={handleChange}
                                                        value={values.transitTime}
                                                        disabled={disabledForm}
                                                        placeholder="Preencha o transit time"
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="transitTime"
                                                    />
                                                </div>
                                                <div className="w-full md:w-1/4 px-2">
                                                    <label htmlFor="freeTime">Free Time</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="freeTime"
                                                        id="freeTime"
                                                        onChange={handleChange}
                                                        value={values.freeTime}
                                                        disabled={disabledForm}
                                                        placeholder="Preencha o free time"
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="freeTime"
                                                    />
                                                </div>
                                                <div className="w-full md:w-1/4 px-2">
                                                    <label htmlFor="expiredDate">
                                                        Validade <span className="text-red">*</span>
                                                    </label>
                                                    <br />
                                                    <DatePicker
                                                        locale="pt"
                                                        selected={values.expiredDate && new Date(values.expiredDate)}
                                                        disabled={disabledForm}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="expiredDate"
                                                        onChange={date => {
                                                            setFieldValue("expiredDate", date);
                                                        }}
                                                        placeholderText="Selecione a validade"
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="expiredDate"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {values.modality === terrestreValue && (
                                        <>
                                            <div className="flex mb-5">
                                                <div className="w-2/12 px-2">
                                                    <label htmlFor="vehicle">Veiculo</label>
                                                    <Select
                                                        options={VehicleOptions}
                                                        value={VehicleOptions.filter(
                                                            option => option.value === values.vehicle
                                                        )}
                                                        isDisabled={disabledForm}
                                                        onChange={item => setFieldValue("vehicle", item?.value)}
                                                        className="outline-none transform-none"
                                                        placeholder=""
                                                    />
                                                </div>
                                                <div className="w-2/12 px-2">
                                                    <label htmlFor="vehicleType">Tipo</label>
                                                    <Select
                                                        options={VehicleTypeOptions}
                                                        value={VehicleTypeOptions.filter(
                                                            option => option.value === values.vehicleType
                                                        )}
                                                        isDisabled={disabledForm}
                                                        onChange={item => setFieldValue("vehicleType", item?.value)}
                                                        className="outline-none transform-none"
                                                        placeholder=""
                                                    />
                                                </div>
                                                <div className="w-2/12 px-2">
                                                    <label htmlFor="package">Embalagem</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="package"
                                                        id="package"
                                                        onChange={handleChange}
                                                        value={values.package}
                                                        disabled={disabledForm}
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="package"
                                                    />
                                                </div>
                                                <div className="w-2/12 px-2">
                                                    <label htmlFor="packegeQtt">Qte Embalagem</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="packegeQtt"
                                                        id="packegeQtt"
                                                        onChange={handleChange}
                                                        value={values.packegeQtt}
                                                        disabled={disabledForm}
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="packegeQtt"
                                                    />
                                                </div>
                                                <div className="w-2/12 px-2">
                                                    <label htmlFor="packageWeight">Peso</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="packageWeight"
                                                        id="packageWeight"
                                                        onChange={handleChange}
                                                        value={values.packageWeight}
                                                        disabled={disabledForm}
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="packageWeight"
                                                    />
                                                </div>
                                                <div className="w-2/12 px-2">
                                                    <label htmlFor="packegeCubicMeters">M3</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="packegeCubicMeters"
                                                        id="packegeCubicMeters"
                                                        onChange={handleChange}
                                                        value={values.packegeCubicMeters}
                                                        disabled={disabledForm}
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="packegeCubicMeters"
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex mb-5">
                                                <div className="w-1/5 px-2">
                                                    <label htmlFor="packageValue">Valor</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="packageValue"
                                                        id="packageValue"
                                                        onChange={handleChange}
                                                        value={values.packageValue}
                                                        disabled={disabledForm}
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="package"
                                                    />
                                                </div>
                                                <div className="w-1/5 px-2">
                                                    <label htmlFor="adValorem">Ad Valorem</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="adValorem"
                                                        id="adValorem"
                                                        onChange={handleChange}
                                                        value={values.adValorem}
                                                        disabled={disabledForm}
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="adValorem"
                                                    />
                                                </div>
                                                <div className="w-1/5 px-2">
                                                    <label htmlFor="gris">GRIS</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="gris"
                                                        id="gris"
                                                        onChange={handleChange}
                                                        value={values.gris}
                                                        disabled={disabledForm}
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="gris"
                                                    />
                                                </div>
                                                <div className="w-1/5 px-2">
                                                    <label htmlFor="icms">ICMS</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="icms"
                                                        id="icms"
                                                        onChange={handleChange}
                                                        value={values.icms}
                                                        disabled={disabledForm}
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="icms"
                                                    />
                                                </div>
                                                <div className="w-1/5 px-2">
                                                    <label htmlFor="others">Outros</label>
                                                    <Field
                                                        type="text"
                                                        className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                        name="others"
                                                        id="others"
                                                        onChange={handleChange}
                                                        value={values.others}
                                                        disabled={disabledForm}
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red mb-4 font-light text-xs"
                                                        name="others"
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full px-2">
                                                <label htmlFor="quoteObservations">Observações</label>
                                                <Field
                                                    as="textarea"
                                                    rows="6"
                                                    className="w-full rounded-md border py-1 px-2 border-C2"
                                                    name="quoteObservations"
                                                    id="quoteObservations"
                                                    onChange={handleChange}
                                                    value={values.quoteObservations}
                                                    disabled={disabledForm}
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red mb-4 font-light text-xs"
                                                    name="quoteObservations"
                                                />
                                            </div>
                                        </>
                                    )}

                                    <div className="mb-20">
                                        <Buttons isSubmitting={isSubmitting} />
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </Card>
                <Card className="mb-5">
                    <article className="flex mt-3 justify-between ">
                        <h5>Documentos</h5>
                        <div className="flex">
                            {!loading && data?.length > 0 && (
                                <button
                                    className="p-1 pl-3 pr-3 mr-2 rounded-md bg-roxo_oficial_light"
                                    style={{
                                        fontSize: "14px",
                                        fontWeight: "550",
                                    }}
                                    onClick={() => downloadAllFiles()}>
                                    {!downloadingAll ? (
                                        <>Baixar todos os documentos</>
                                    ) : (
                                        <ClipLoader size={20} loading={true} />
                                    )}
                                </button>
                            )}
                            <ModalDocsUpload quote={entity} onSuccess={loadList} />
                        </div>
                    </article>
                    {loading ? (
                        <div>
                            <ClipLoader size={20} loading={true} />
                        </div>
                    ) : (
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{ width: "35%" }}>Nome</th>
                                    <th style={{ width: "9%" }}>Extensão</th>
                                    <th style={{ width: "9%" }}>Data</th>
                                    <th style={{ width: "5%" }}>Hora</th>
                                    <th style={{ width: "10%" }}>Postado por</th>
                                    <th style={{ width: "10%" }}>Tipo</th>
                                    <th style={{ width: "12%" }} className="text-center">
                                        Ações
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map(doc => {
                                    const newCreatedAt = new Date(doc?.createdAt);
                                    const arrName = doc?.uploadName?.split(".");

                                    return (
                                        <tr key={doc.identifier}>
                                            <td title={arrName[0]}>{FormatString(arrName[0])}</td>
                                            <td>{arrName.pop()}</td>
                                            <td>{format(newCreatedAt, "dd/MM/yyyy")}</td>
                                            <td>{format(newCreatedAt, "HH:mm")}</td>
                                            <td>{doc?.user?.name}</td>
                                            <td>{doc?.type}</td>
                                            <td className="text-center">
                                                <button
                                                    className="p-2 mr-2 mt-2 rounded-md bg-C2"
                                                    title="Visualizar documento"
                                                    onClick={() =>
                                                        actionModalShow(entity.identifier, doc.identifier, arrName[0])
                                                    }>
                                                    <SearchIcon />
                                                </button>
                                                <button
                                                    className="p-2 mr-2 mt-2 rounded-md bg-roxo_oficial_light"
                                                    title="Baixar documento"
                                                    onClick={() => downloadFile(doc.identifier, arrName[0], arrName.pop())}>
                                                    {!downloadingFile || downloadingFile !== doc.identifier ? (
                                                        <DownloadIcon />
                                                    ) : (
                                                        <ClipLoader size={20} loading={true} />
                                                    )}
                                                </button>
                                                <button
                                                    className="p-2 mt-2 rounded-md bg-canal_vermelho"
                                                    title="Remover documento"
                                                    onClick={() => actionModalRemove(entity.identifier, doc)}>
                                                    <FaTrashAlt color="#fff" />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}

                                {data?.length === 0 && (
                                    <tr className="text-center">
                                        <td className="text-center" colSpan="10">
                                            Sem documentos nesta cotação.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    )}
                    {meta && meta.total_pages > 1 && (
                        <Paginate meta={meta} setMeta={setMeta} action={action} setAction={setAction} showDetails={true} />
                    )}
                </Card>
            </div>
        </Layout>
    );
};

export default EditBudgetForm;